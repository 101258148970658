<template>
    <div class="stall-trade-table">
        <el-collapse v-model="currentPurchasers" v-if="tableData.length > 0">
            <el-collapse-item v-for="(purchase, pIndex) in tableData" :key="pIndex" :name="purchase.Purchaser.Name">
                <template slot="title">
                    <div @click.stop style="width: calc(100% - 30px);cursor:auto;">
                        <el-tooltip placement="top-start">
                            <div slot="content">点击人像<br />可编辑 / 查看不同客户之间的关系映射</div>
                            <span style="cursor: pointer;" @click="showPurchaserMapping(purchase)">
                                <img :src="`/static/img/userset${purchase.Purchaser.ParentPurchaserID ? '1' : ''}.png`" />
                                <span class="bold-info span_inline">
                                    {{ (purchase.Purchaser.Nick != '' ? '(' + purchase.Purchaser.Nick + ')' : '') +
                                        (purchase.Purchaser.ParentName ? purchase.Purchaser.ParentName :
                                    purchase.Purchaser.Name) }}
                                </span>
                            </span>
                        </el-tooltip>
                        <span style="margin-left:10px;">指定价比商品价便宜：</span>
                        <span>
                            <el-input v-model="purchase.Purchaser.PriceDiscount" style="width: 75px;" @change="handlePriceDiscount(purchase)"
                                v-format.float></el-input> 元
                        </span>
                        <span style="margin-left:10px;" v-if="$store.state.routes.systemSetting.MultiWarehouse">
                            发货仓库：<warehouse-select :value.sync="purchase.Purchaser.WID" :set_default="true"
                                select_style="width:120px;"></warehouse-select>
                        </span>
                        <span style="margin-left:10px;" v-if="$store.state.routes.systemSetting.MultiStore">
                            所属档口：<store-select :disabled="!content" :value.sync="purchase.Purchaser.StoreID" :set_default="true"
                                select_style="width:140px;"></store-select>
                        </span>
                    </div>
                </template>
                <el-card v-for="(trade, index) in purchase.Trades" :key="index">
                    <div slot="header" v-if="trade.ProxyStatus == 0">
                        <span class="bold-info" style="color: black;">报单：</span>共
                        <span class="bold-info">{{ trade.ItemNum }}</span>
                        款商品，合计数量：
                        <span class="bold-info money">{{ trade.Num }}</span>
                        件，合计金额：
                        <span class="bold-info money">
                            {{ trade.TradeDetailList.some(t => t.ProductID == 0) ?
                                "待完善价格" : trade.Payment }}
                        </span>{{ trade.TradeDetailList.some(t => t.ProductID == 0) ? '' : ' 元' }}
                        <span>
                            ，报单时间：
                            <el-date-picker :disabled="!content" v-model="trade.CreateTime" style="width: 190px;" type="datetime" placeholder="选择日期时间"
                                align="right" :picker-options="datePickerOptions" :clearable="false">
                            </el-date-picker>
                        </span>
                    </div>
                    <div slot="header" v-if="trade.ProxyStatus == 1">
                        <div>
                            <span class="bold-info" style="color: black;">代发单：</span>
                            收件人： <span class="bold-info proxy">
                                {{ trade.Proxy.ReceiverName }}</span>，手机：<span class="bold-info proxy">{{
                                    trade.Proxy.ReceiverMobile }}</span>， 地址：<span style="color: #ff6000;">{{
                                    trade.Proxy.ReceiverProvince }} {{ trade.Proxy.ReceiverCity }} {{
                                    trade.Proxy.ReceiverDistrict }} {{ trade.Proxy.ReceiverAddress }}</span>
                        </div><br />
                        <div class="address">
                            <el-popover placement="bottom-start" width="270" trigger="hover">
                                <div style="border: 1px solid #a7a7a7; margin-bottom: 5px;padding: 5px;">
                                    【代发账单】<br />
                                    收件人：{{ trade.Proxy.ReceiverName + ' ' + trade.Proxy.ReceiverMobile }}<br />
                                    商品金额：￥{{ trade.TradeDetailList.some(t => t.ProductID == 0) ?
                                    "待完善价格" : trade.Payment }}<br />
                                    运费：￥{{ trade.Proxy.PostFee }}<br />
                                    应付金额：￥{{ trade.TradeDetailList.some(t => t.ProductID == 0) ?
                                        "待完善价格" : trade.Payment + trade.Proxy.PostFee | decimalFormat }}</div>
                                <el-button type="primary" style="width: 100%;" @click="handleProxySendBill(trade)">发送</el-button>
                                <el-button type="success" slot="reference">账单</el-button>
                            </el-popover>
                            <el-popover placement="bottom-start" width="270" trigger="hover" title="快递设置：" style="padding: 0 5px;">
                                <el-form label-width="90px">
                                    <el-form-item label="快递名称：">
                                        <el-input v-model="trade.Proxy.ExpressName" />
                                    </el-form-item>
                                    <el-form-item label="快递单号：">
                                        <el-input v-model="trade.Proxy.ExpressNo" />
                                    </el-form-item>
                                </el-form>
                                <el-button type="primary" slot="reference">快递设置</el-button>
                            </el-popover>
                            <el-tooltip v-if="trade.Proxy.Tid" :content="trade.Proxy.Tid">
                                <el-button type="text">外部订单号</el-button>
                            </el-tooltip>
                            运费：<el-input v-model="trade.PostFee" style="width: 80px;" v-format.float @change="handlePostFeeSave(trade)" /> 元，
                            合计金额：<span class="bold-info money">{{ trade.TradeDetailList.some(t => t.ProductID == 0) ?
                                '待完善价格' : trade.Payment + trade.Proxy.PostFee | decimalFormat }}</span>{{
                                    trade.TradeDetailList.some(t =>
                                        t.ProductID == 0) ? '' : ' 元' }}
                        </div>
                    </div>
                    <el-table class="middleTable" :data="trade.TradeDetailList" v-loading="loading" :cell-class-name="handleCellClassName"
                        :span-method="objectSpanMethod" height="100%"
                        :header-cell-style="{ 'background-color': '#E5EEF6', 'border-color': 'rgba(218, 223, 228, 1)', height: '48px', }">
                        <el-table-column type="index" label="编号" header-align="center" align="center" width="50">
                        </el-table-column>
                        <el-table-column prop="ItemNo" label="货号" header-align="center" align="center" width="130">
                            <template slot-scope="{ row }">
                                <el-tooltip v-if="row.IsException" placement="top-start">
                                    <div slot="content">商品异常，可点击编辑<br />商品颜色尺码与报单不一致</div>
                                    <span class="el-icon-warning hand" @click="handleEditProductShow(row)">{{
                                        row.ItemNo }}</span>
                                </el-tooltip>
                                <el-tooltip v-else-if="row.IsOffSale" placement="top-start">
                                    <div slot="content">商品已下架</div>
                                    <span class="el-icon-warning">{{ row.ItemNo }}</span>
                                </el-tooltip>
                                <span v-else>{{ row.ItemNo }}</span>
                                <span v-if="row.ProductID > 0" class="el-icon-edit-outline hand" @click="handleDialogShow(row, 'item')"></span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Color" label="颜色" header-align="center" align="center" width="100">
                            <template slot-scope="{ row }">
                                <span v-if="!row.IsNew || row.ProductID == 0">{{ row.Color }}</span>
                                <el-tooltip v-else placement="top-start">
                                    <div slot="content">颜色不存在，可点击处理<br />新增颜色、映射到其他颜色</div>
                                    <el-button type="text" style="color:#6ab319;font-size: 14px;" @click="showColorEdit(row)">{{ row.Color
                                        }}</el-button>
                                </el-tooltip>
                                <span v-if="row.ProductID > 0 && row.Color" class="el-icon-edit-outline hand"
                                    @click="!row.IsNew ? handleDialogShow(row, 'color') : showColorEdit(row)"></span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="Price" label="商品价" header-align="center" align="center" width="100">
                            <template slot-scope="{ row }">
                                <el-input v-model="row.Price" :value="row.Price <= 0 ? '' : row.Price"
                                    @focus="row.ProductID == 0 ? handleEditProductShow(row) : handleDialogShow(row, 'item')" readonly
                                    style="width:75px;"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column prop="SettlePrice" label="指定价" header-align="center" align="center" width="100">
                            <template slot-scope="{ row }">
                                <el-input v-model="row.SettlePrice" :value="row.SettlePrice <= 0 ? '' : row.SettlePrice" readonly
                                    @focus="row.ProductID == 0 ? handleEditProductShow(row) : handleDialogShow(row, 'item')"
                                    style="width:75px;"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column prop="" label="尺码*数量" header-align="center" align="left">
                            <template slot-scope="{ row }">
                                <el-tag v-for="( sizeDetail, sizeIndex ) in row.Details " :type="row.ProductID == 0 ? 'success' : ''"
                                    :key="row.ItemNo + row.Color + sizeDetail.Size + sizeIndex" :title="row.ItemNo + row.Color + sizeDetail.Size"
                                    class="size-tag">
                                    <template v-if="sizeDetail.SpecErrorMsg">
                                        <el-tooltip placement="top-start">
                                            <div slot="content">{{ sizeDetail.SpecErrorMsg }}</div>
                                            <div class="el-icon-warning">
                                                <span v-if="sizeDetail.Size">{{ sizeDetail.Size }}</span>
                                                <span v-if="sizeDetail.Size"
                                                    style="height: 20px;display: inline-block;vertical-align: middle;text-align: center;">*</span>
                                                <span>{{ sizeDetail.Num }}</span>
                                            </div>
                                        </el-tooltip>
                                    </template>
                                    <template v-else>
                                        <span v-if="sizeDetail.Size">{{ sizeDetail.Size }}</span>
                                        <span v-if="sizeDetail.Size"
                                            style="height: 20px;display: inline-block;vertical-align: middle;text-align: center;">*</span>
                                        <span>{{ sizeDetail.Num }}</span>
                                        <span style="color: #F56C6C;"
                                            v-if="$store.state.routes.systemSetting.CheckStock && sizeDetail.Num > sizeDetail.Stock">{{
                                                ' 缺 ' + (sizeDetail.Num - sizeDetail.Stock) }}</span>
                                    </template>
                                </el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="SupplierName" label="供应商" header-align="center" align="center" width="130">
                            <template slot-scope="{ row }">
                                <el-autocomplete placeholder="供应商" v-model.trim="row.SupplierName" value-key="SupplierName"
                                    :fetch-suggestions="loadSuppliers" @select="handleSaveSupplier(row)" @change="handleSupplierChange(row)"
                                    @focus="row.ProductID == 0 ? handleEditProductShow(row) : ''">
                                    <el-button slot="append" icon="el-icon-s-tools" v-if="suppliers.some(t => t.SupplierID == row.SupplierID)"
                                        @click="handleSupplierSetting(row)"></el-button>
                                </el-autocomplete>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-input v-model="trade.Remark" placeholder="请输入备注，打印会显示备注" style="margin-top:8px;"></el-input>
                </el-card>
            </el-collapse-item>
        </el-collapse>
        <edit-product ref="productDlg" @handleSearch="analyse" />
        <product-setting ref="productSettingDlg" @handleSearch="analyse" />
        <el-dialog v-dialog-drag title="请选择颜色处理方式" width="500px" :visible="colorEditParam.visible" :close-on-click-modal="false"
            @close="colorEditParam.visible = false">
            <el-form label-width="120px" label-position="right" :model="colorEditParam">
                <el-form-item label="处理类型：">
                    <el-radio v-model="colorEditParam.type" :label="0">新增颜色</el-radio>
                    <el-radio v-model="colorEditParam.type" :label="1">映射颜色</el-radio>
                </el-form-item>
                <el-form-item label="当前颜色：">
                    {{ colorEditParam.color }}
                    <span v-if="colorEditParam.type == 1"><i class="el-icon-right" style="padding: 0 10px;"></i>
                        <el-select placeholder="选择别名对应的颜色" v-model="colorEditParam.selectColor" v-if="colorEditParam.itemColors.length > 0">
                            <el-option v-for=" item in colorEditParam.itemColors " :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select></span>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="colorEditParam.visible = false">取消</el-button>
                <el-button type="primary" size="small" @click="saveColorEdit">保存</el-button>
            </div>
        </el-dialog>
        <purchaser-mapping ref="purchaserMappingDlg" @handleSearch="analyse" />
        <edit-supplier ref="editSupplier" @handleChange="handleSupplierSettingSave" />
    </div>
</template>
<script>
import { submit } from "@/api/base";
import { Loading } from 'element-ui';
import EditProduct from "@/views/product/simple/components/Edit.vue";
import ProductSetting from "@/views/product/simple/components/ProductSetting.vue";
import PurchaserMapping from "@/views/stall/trade/components/PurchaserMapping.vue";
import WarehouseSelect from "@/views/components/common/WarehouseSelect.vue";
import EditSupplier from "@/views/purchase/components/editSupplier.vue";
import StoreSelect from "@/views/components/common/StoreSelect.vue";
import { floatFormat } from "@/utils/validate";
import { getDateTimePickerOptions } from '@/utils/tool';
export default {
    name: "StallTradeTable",
    components: {
        EditProduct,
        ProductSetting,
        PurchaserMapping,
        WarehouseSelect,
        EditSupplier,
        StoreSelect
    },
    props: {
        addEnable: { type: Boolean }
    },
    data() {
        return {
            currentPurchasers: [],
            datePickerOptions: getDateTimePickerOptions(),
            loading: false,
            messageData: {},
            content: '',
            tableData: [],
            isTableRefresh: false,
            dialogVisible: false,
            suppliers: [],
            spanArrData: {},
            colorEditParam: {
                productID: 0,
                color: '',
                type: 0,//0新增颜色 1增加别名
                selectColor: '',
                itemColors: [],
                visible: false
            }
        };
    },
    methods: {
        //配置来源初始化解析内容
        init(messageData = null, content = null) {
            this.messageData = messageData ? messageData : {};
            this.content = content;
            this.tableData = [];
            if (!this.messageData.ids && !this.content) {
                return;
            }
            this.analyse();
        },
        //执行解析请求
        async analyse() {
            let datas = [];
            if (this.messageData.ids || this.content) {
                //提交解析
                this.loading = true;
                let res;
                if (this.messageData.ids) {
                    res = await submit("/api/stallBotMessage/analyse", { ids: this.messageData.ids });
                }
                else {
                    res = await submit("/api/stall/analyse", { content: this.content });
                }
                this.loading = false;

                if (res.msg) {
                    this.$message({ type: "error", message: "解析失败，" + res.msg });
                    return;
                }
                datas = res.data;
            }
            this.setData(datas);
        },
        //设置解析内容
        async setData(datas) {
            this.tableData = [];
            this.currentPurchasers = '';
            if (datas.length > 0) {
                await this.fetchSuppliers();
                this.currentPurchasers = datas.map((item) => { return item.Purchaser.Name; });
            }
            (datas).forEach(purchase => {
                var isPartSuccess = false;

                //分析结果
                var error = "";
                if (purchase.Trades) {
                    isPartSuccess = true;
                }
                if (purchase.ErrorMsg) {
                    if (error != "") {
                        error += "<br/>";
                    }
                    error += purchase.ErrorMsg;
                }
                if (error) {
                    const errMsg = `${isPartSuccess ? "部分解析成功" : "解析失败"}，${error}`;
                    this.$baseMessage(errMsg, "error");
                }
                if (this.messageData.storeID) {
                    purchase.Purchaser.StoreID = this.messageData.storeID;
                }
                purchase.Remark = '';
                purchase.Trades.forEach((trade, index) => {
                    if (trade.ProxyStatus == 1) {
                        trade.PostFee = trade.Proxy.PostFee;
                    }
                    this.mergeTable(purchase, index);//刷新合并
                });
                this.tableData.push(purchase);
            });
            this.$emit('update:addEnable', true);
        },
        //商品编辑操作
        handleEditProductShow(row) {
            let postData = {
                ItemNo: row.ItemNo,
                ProductID: row.ProductID
            };
            if (row.ProductID > 0) {
                this.$refs.productDlg.initDialog('编辑商品', row.ProductID, postData);
            }
            else {
                let colors = [];
                let sizes = [];
                this.tableData.forEach(purchase => {
                    purchase.Trades.forEach(trade => {
                        trade.TradeDetailList.forEach(detail => {
                            if (detail.ItemNo == row.ItemNo) {
                                if (detail.Color && !colors.includes(detail.Color)) {
                                    colors.push(detail.Color);
                                }
                                detail.Details.forEach(size => {
                                    if (size.Size && !sizes.includes(size.Size)) {
                                        sizes.push(size.Size);
                                    }
                                });
                            }
                        });
                    });
                });
                postData.Colors = colors.join(',');
                postData.Sizes = sizes.join(',');
                postData.tips = `商品不存在，请先添加商品！`;
                this.$refs.productDlg.initDialog('新增商品', null, postData);
            }
        },
        //页面弹窗操作
        handleDialogShow(row, type) {
            let postData = {
                ItemNo: row.ItemNo,
                ProductID: row.ProductID,
                PurchaserID: row.PurchaserID,
                PurchaserName: row.PurchaserName,
                IncludeSettle: true,
                Type: type
            };
            this.$refs.productSettingDlg.open(postData);
        },
        async showColorEdit(row) {
            if (this.colorEditParam.itemColors.length == 0 || this.colorEditParam.productID != row.ProductID) {
                const { data } = await submit("/api/product/getProductColors", { ProductID: row.ProductID });
                this.colorEditParam.itemColors = data;
            }
            this.colorEditParam.color = row.Color;
            this.colorEditParam.type = 1;
            this.colorEditParam.productID = row.ProductID;
            this.colorEditParam.selectColor = '';
            this.colorEditParam.visible = true;
        },
        async saveColorEdit() {
            if (this.colorEditParam.type == 1 && !this.colorEditParam.selectColor) {
                this.$baseMessage("请选择映射对应的颜色", "error");
                return;
            }
            let param = {
                type: this.colorEditParam.type,
                productID: this.colorEditParam.productID,
                color: '',
                rename: '',
            };
            if (this.colorEditParam.type == 0) {
                param.color = this.colorEditParam.color;
            }
            else {
                param.color = this.colorEditParam.selectColor;
                param.rename = this.colorEditParam.color;
            }
            await submit("/api/product/saveProductColor", param);
            this.colorEditParam.visible = false;
            this.colorEditParam.itemColors = [];
            this.analyse();
        },
        //校验获取提交内容
        checkData() {
            this.tableData.forEach((purchase) => {
                purchase.Trades.forEach((trade, tradeIndex) => {
                    trade.TradeDetailList.forEach((data, rowIndex) => {
                        if (data.ProductID == 0) {
                            throw Error(`采购商：${data.PurchaserName}</br>第${tradeIndex + 1}个${trade.ProxyStatus == 0 ? '报单' : '代发单'}：第${rowIndex + 1}行货号${data.ItemNo}不存在，请先添加商品！`);
                        }
                        if (data.IsException) {
                            throw Error(`采购商：${data.PurchaserName}</br>第${tradeIndex + 1}个${trade.ProxyStatus == 0 ? '报单' : '代发单'}：第${rowIndex + 1}行商品${data.ItemNo}异常，请先修改商品或报单！`);
                        }

                        var errors = [];
                        if (data.Price == "" || data.Price <= 0) {
                            errors.push("商品价");
                        }
                        if (data.SettlePrice == "" || data.SettlePrice <= 0) {
                            errors.push("指定价");
                        }
                        if (errors.length > 0) {
                            throw Error(`采购商：${data.PurchaserName}</br>第${tradeIndex + 1}个${trade.ProxyStatus == 0 ? '报单' : '代发单'}：第${rowIndex + 1}行货号${data.ItemNo}，${errors.join("、")}要大于0`);
                        }
                    });
                });
            });
        },
        handlePayTypeChange(trade, payType) {
            trade.PayStatus = payType == 'credit' ? 0 : 1;
            trade.PayType = payType;
        },
        //新增订单
        async handleAddOrder() {
            if (!this.addEnable) {
                this.$baseMessage("保存修改中，请稍后审核", "error");
                return;
            }
            //校验数据
            try {
                this.checkData();
            } catch (e) {
                this.$baseMessage(e.message, "error");
                return;
            }

            this.$emit('update:addEnable', false);
            this.loading = true;
            let loadingInstance = Loading.service({ target: "#main-wrapper", text: "数据提交中..." });
            const { data } = await submit("/api/stall/add", {
                Details: this.tableData,
                BotMessageIds: this.messageData.ids
            });
            loadingInstance.close();
            this.loading = false;
            if (data.ErrorMsg) {
                this.$baseMessage("提交失败：" + data.ErrorMsg, "error");
                this.$emit('update:addEnable', true);
                return;
            }
            else {
                this.$message({ type: "success", message: `提交成功` });
            }
            //刷新消息
            this.$emit("handleSave");
        },
        //加载供应商
        async fetchSuppliers() {
            const { data } = await submit('/api/purchase/supplier/getList', {
                CurrentPage: 1,
                PageSize: 1000,
            });
            this.suppliers = data.datas || [];
        },
        //价格便宜设置
        async handlePriceDiscount(row) {
            this.$emit('update:addEnable', false);
            if (row.Purchaser.PurchaserID) {
                await submit('/api/stall/savePurchaser', { changeType: 'PriceDiscount', purchaser: row.Purchaser });
                this.analyse();
            }
            else {
                this.handleDetailStatistics(row);
                this.$emit('update:addEnable', true);
            }
        },
        handleCellClassName({ row, column }) {
            if (["Price", "SettlePrice"].includes(column.property) && row.ProductID == 0) {
                return "require-tag";
            }
            if (["Color"].includes(column.property) && row.IsNew && row.ProductID > 0) {
                return "require-tag";
            }
            return "";
        },
        //金额修改
        handleDetailStatistics(purchase) {
            purchase.Trades.forEach(trade => {
                trade.Payment = 0.0;
                trade.TradeDetailList.forEach((item) => {
                    item.SettlePrice = floatFormat(item.Price - purchase.Purchaser.PriceDiscount);
                    item.Details.forEach(detail => {
                        detail.SettlePrice = floatFormat(detail.Price - purchase.Purchaser.PriceDiscount);
                        trade.Payment += detail.SettlePrice * detail.Num;
                    });

                    if (item.Price <= 0) {
                        item.Price = null;
                    }
                    if (item.SettlePrice <= 0) {
                        item.SettlePrice = null;
                    }
                });
            });
        },
        // 合并单元格
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            var purchaseSpanData = this.spanArrData[row.PurchaserName][row.Index];
            if (columnIndex === 1) {
                const _row = purchaseSpanData.SpanItems[rowIndex];
                const _col = _row > 0 ? 1 : 0;
                return {
                    rowspan: _row,
                    colspan: _col
                };
            }
            if (columnIndex === 6) {
                const _row = purchaseSpanData.SpanSuppliers[rowIndex];
                const _col = _row > 0 ? 1 : 0;
                return {
                    rowspan: _row,
                    colspan: _col
                };
            }
        },
        mergeTable(purchase, index) {
            const key = purchase.Purchaser.Name;
            const data = purchase.Trades[index].TradeDetailList;
            // 设定一个数组spanArr来存放要合并的格数，同时还要设定一个变量pos来记录
            var spanItems = [];
            var pos = 0;
            var spanSuppliers = [];
            var pos1 = 0;
            for (var c = 0; c < data.length; c++) {
                purchase.Trades[index].TradeDetailList[c].PurchaserID = purchase.Purchaser.PurchaserID;
                purchase.Trades[index].TradeDetailList[c].PurchaserName = key;
                purchase.Trades[index].TradeDetailList[c].Index = index;
                if (c === 0) {
                    spanItems.push(1);
                    pos = 0;
                    spanSuppliers.push(1);
                    pos1 = 0;
                } else {
                    if (data[c].ItemNo === data[c - 1].ItemNo) {
                        spanItems[pos] += 1;
                        spanItems.push(0);
                    } else {
                        spanItems.push(1);
                        pos = c;
                    }

                    //供应商根据货号合并
                    if (data[c].ItemNo === data[c - 1].ItemNo && data[c].SupplierName === data[c - 1].SupplierName) {
                        spanSuppliers[pos1] += 1;
                        spanSuppliers.push(0);
                    } else {
                        spanSuppliers.push(1);
                        pos1 = c;
                    }
                }
            }
            if (!this.spanArrData[key]) {
                this.spanArrData[key] = {};
            }
            this.spanArrData[key][index] = { SpanItems: spanItems, SpanSuppliers: spanSuppliers };
        },
        async loadSuppliers(qs, cb) {
            const suppliers = this.suppliers;
            var results = qs ? suppliers.filter(t => t.SupplierName.toLowerCase().includes(qs.toLowerCase())) : suppliers;
            cb(results || []);
        },
        async handleSaveSupplier(row) {
            this.handleSupplierChange(row);
            if (row.ProductID > 0) {
                await submit('/api/product/changeSupplier', { ProductID: row.ProductID, DefaultSupplierName: row.SupplierName });
            }
        },
        handleSupplierChange(row) {
            this.$emit('update:addEnable', false);
            this.tableData.forEach((item) => {
                item.Trades.forEach((trade) => {
                    trade.TradeDetailList.forEach((childItem) => {
                        if (childItem.ItemNo == row.ItemNo && childItem.SupplierName != row.SupplierName) {
                            childItem.SupplierName = row.SupplierName;
                        }
                    });
                });
            });
            this.$emit('update:addEnable', true);
        },
        //页面关联弹窗操作
        showPurchaserMapping(row) {
            this.$refs.purchaserMappingDlg.show(row.Purchaser);
        },
        async handlePostFeeSave(trade) {
            this.$emit('update:addEnable', false);
            trade.Proxy.PostFee = floatFormat(trade.PostFee);
            await submit("/api/stall/proxy/save", { saveType: 1, trade: trade });
            this.$emit('update:addEnable', true);
        },
        async handleProxySendBill(trade) {
            if (!this.messageData.sender) {
                return;
            }
            var content = `【代发账单】\r\n收件人：${trade.Proxy.ReceiverName} ${trade.Proxy.ReceiverMobile}\r\n`;
            content += `商品金额：￥${trade.Payment}\r\n运费：￥${trade.Proxy.PostFee}\r\n`;
            content += `应付金额：￥${floatFormat(trade.Payment + trade.Proxy.PostFee)}`;
            const res = await submit('/api/stallBot/send', {
                BotUid: this.messageData.sender.BotUid,
                SenderUid: this.messageData.sender.SenderUid,
                Content: content
            });
            if (res.data) {
                this.$baseMessage(res.data, "error");
                return;
            }
            this.$message({ type: "success", message: `发送成功!` });
            //刷新消息
            this.$emit("handleSave");
        },
        async handleSupplierSetting(row) {
            var supplier = this.suppliers.filter(t => t.SupplierName == row.SupplierName);
            if (supplier.length == 0) {
                return;
            }
            var formValue = {
                SupplierID: supplier[0].SupplierID,
                qq: supplier[0].ConfigData && supplier[0].ConfigData.qqSetting ? supplier[0].ConfigData.qqSetting.qq : null,
                botQQ: supplier[0].ConfigData && supplier[0].ConfigData.qqSetting ? supplier[0].ConfigData.qqSetting.botQQ : null,
                type: supplier[0].ConfigData && supplier[0].ConfigData.qqSetting ? supplier[0].ConfigData.qqSetting.type : null,
                market: supplier[0].Market,
                noCustom: true,
            };
            this.$refs.editSupplier.init(formValue);
        },
        async handleSupplierSettingSave(settingValue) {
            var supplier = this.suppliers.filter(t => t.SupplierID == settingValue.SupplierID);
            if (supplier.length == 0) {
                return;
            }
            supplier[0].Market = settingValue.market;
            supplier[0].ConfigData.qqSetting.qq = settingValue.qq;
            supplier[0].ConfigData.qqSetting.type = settingValue.type;
            supplier[0].ConfigData.qqSetting.botQQ = settingValue.botQQ;
            await submit('/api/purchase/supplier/editSend', {
                SupplierID: supplier[0].SupplierID,
                ConfigData: supplier[0].ConfigData,
                Market: supplier[0].Market
            });
            this.$baseMessage("保存成功", "success");
        }
    },
};
</script>

<style lang="scss" scoped>
.stall-trade-table {
    overflow-y: auto;

    img {
        vertical-align: middle;
        padding-left: 15px;
    }

    .bold-info {
        font-weight: bold;
        font-size: 18px;
        color: #409EFF;
    }

    .span_inline{
        text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
        display: inline-flex;
        max-width: 250px;
    }

    .bold-info.proxy {
        color: #ff6000 !important;
    }

    .bold-info.money {
        color: #F56C6C !important;
    }

    .hand {
        cursor: pointer;
    }

    .el-icon-warning {
        color: #F56C6C !important;
    }

    .size-tag:first-child {
        margin-left: 10px;
    }

    .size-tag {
        font-size: 14px;

        .out-stock {
            width: 50px;

            ::v-deep {
                .el-input__inner {
                    height: 25px !important;
                    vertical-align: middle;
                    padding: 0px 5px;
                }
            }
        }
    }

    .middleTable {
        border: 1px solid #a7a7a7 !important;
        border-right: 0 !important;

        ::v-deep {
            .el-table__cell {
                border-color: #a7a7a7 !important;
            }

            .el-table__cell {
                border-right: 1px solid;
            }

            .el-input-group__append {
                padding: 0 8px !important;
            }
        }
    }

    ::v-deep {
        .el-collapse-item__content {
            padding-bottom: 0;
        }

        .el-collapse-item__wrap {
            border-bottom: 2px dashed #a7a7a7;
        }

        .el-card__header {
            padding: 10px 20px;
            position: relative;

            &>div {
                &>div {
                    display: inline-block;
                    vertical-align: top;
                    line-height: 30px;
                }
            }

            .el-button {
                vertical-align: middle;
                margin-right: 10px;
            }
        }

        .el-card__body {
            padding: 0;
        }

        .require-tag {
            &::before {
                content: "新";
                height: 15px;
                width: 15px;
                position: absolute;
                top: 2px;
                right: 2px;
                color: #6ab319;
                border-radius: 15px;
                border: solid 1px #6ab319;
                padding: 0 0 1px 0;
                font-size: 10px;
                z-index: 50;
            }
        }
    }
}
</style>